import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import pageStyles from "../styles/pages/page-styles.module.scss"
import styles from "../styles/pages/contact.module.scss"

class Contact extends React.Component {
  constructor(props) {
    super(props)

    this.state = { name: "", company: "", email: "", phone: "", message: "" }
  }
  render() {
    const pageData = this.props.data.allContentfulContactPage.edges[0].node
    const contactBoxText = pageData.contactBoxText.contactBoxText
    const email = pageData.contactEmail
    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Contact" />
        <div className={pageStyles.pageContentContainer}>
          <div className={pageStyles.pageTitle}>Contact</div>

          <div className={styles.flexContainer}>
            <div className={styles.leftColumn}>
              {pageData.locations &&
                pageData.locations.map(location => {
                  return (
                    <div>
                      {/* <div className={styles.imageFull}>
                      <Img fluid={location.image.fluid} alt="NYC skyline" />
                    </div> */}
                      <div className={styles.location}>
                        <div className={styles.city}>{location.location}</div>
                        <div>{location.addressLineOne}</div>
                        <div>{location.addressLineTwo}</div>
                        <div className={styles.phone}>{location.phone}</div>
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className={styles.rightColumn}> 
              <div className={styles.contactBox}>
                <div className={styles.header}>Contact</div>
                <div className={styles.text}>
                  {contactBoxText}
                  <a href={`mailto:${email}`}>{email}</a> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const query = graphql`
  query {
    allContentfulContactPage {
      edges {
        node {
          locations {
            location
            addressLineOne
            addressLineTwo
            phone
            image {
              fluid(quality: 100, maxWidth: 582) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          contactBoxText {
            contactBoxText
          }
          contactEmail
        }
      }
    }
  }
`
